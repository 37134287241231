@use 'libs/styles/variables';

.content {
  border: none;
  background: transparent;
}

.body {
  @include variables.paragraph;
  border: none;
  border-radius: variables.$border-radius-s;
  color: variables.$color-white;
  background-color: #393f49ee;
  box-shadow: var(--chakra-shadows-md);
  opacity: 1;

  a {
    text-decoration: underline;
    color: variables.$color-org;

    &:hover {
      color: variables.$color-org-2;
    }
  }
}
