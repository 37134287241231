@use 'libs/styles/variables';

.hover-content {
  @include variables.paragraph;
  display: block;
  border: none;
  padding: 0.75rem 1rem;
  color: variables.$color-white;
  background-color: variables.$color-dark-light;

  a {
    text-decoration: underline;
    color: variables.$color-sky;

    &:hover {
      color: variables.$color-org;
    }
  }
}
