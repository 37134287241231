@use 'libs/styles/variables';

.cookie-modal {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 100;
  display: flex;
  gap: 2rem;
  align-items: center;
  width: 100%;
  max-width: 36rem;
  border-bottom: none;
  border-left: none;
  border-top-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  background: variables.$color-white;

  h3 {
    @include variables.title(4);
  }

  & > div {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  & > button {
    margin-left: auto;
  }

  @media (max-width: variables.$device-md) {
    width: calc(100% - 5rem);
    border-left: unset;
  }
}
