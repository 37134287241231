@use 'libs/styles/variables';

.typeform-modal {
  width: 80vw;
  height: 80vh;
  border-radius: 40px;
  padding: 0;
  overflow: hidden;
  background-color: variables.$color-white;
}

.typeform-iframe {
  flex: 1;
}
