@use 'libs/styles/variables';
@use 'libs/styles/layout';
@use 'libs/styles/utils';

@mixin x-button($color) {
  @include utils.increase-specificity(2) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.5rem;
    height: 1.5rem;
    border: none;
    border-radius: 50%;
    padding: 0;
    color: $color;
    background: rgba($color, 0.125);
    cursor: pointer;
    transition: variables.$transition-s;
  }

  & > svg {
    width: 1rem;
    height: 1rem;
    color: $color;
  }

  &:hover {
    @include utils.increase-specificity(2) {
      background: rgba($color, 0.375);
    }
  }
}

@mixin tabs {
  position: sticky;
  top: 6rem;
  z-index: 10;
  align-self: center;
  margin-bottom: 2rem;
  color: var(--color, variables.$color-org-2);
  box-shadow: variables.$box-shadow-light-purple;

  & > button {
    @include utils.increase-specificity(2) {
      padding-left: 1rem;
    }
    border-width: 1px;
    padding-right: 1.5rem;

    &[data-filled='false']:not(:hover) {
      span {
        color: variables.$color-dark-light;
      }
      background: variables.$color-near-white;
    }
  }

  & > button > svg {
    height: 28px;
    color: var(--color);
  }

  &--small {
    & > button {
      @include utils.increase-specificity(2) {
        padding: 0.75rem 1rem;
        font-size: 1rem;
      }
    }

    & > button > svg {
      height: 22px;
    }
  }

  & > button:hover {
    color: var(--color, white);
  }

  & > :first-child {
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    padding-left: 1rem;
  }

  & > :last-child {
    border-left-width: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

@mixin for-link($color, $bg: true) {
  @include utils.increase-specificity(2) {
    gap: 0.5rem;
    height: unset;
    box-shadow: none;

    @if $bg {
      gap: 0.65rem;
      border-radius: 2rem;
      padding: 0.5rem 0.75rem;
      padding-right: 1.25rem;
    }

    @media (max-width: variables.$device-sm) {
      @if $bg {
        padding-right: 0.5rem;
        padding-left: 0.25rem;
      }
    }

    h2 {
      font-size: 1.125rem;
      font-weight: 400;
      letter-spacing: 0;

      @media (max-width: variables.$device-sm) {
        font-size: 0.9rem;
      }
    }

    &,
    svg,
    h2 {
      color: $color;
    }

    @if $bg {
      &:hover {
        svg,
        h2 {
          color: white;
        }
      }
    }

    > :first-child {
      @media (max-width: variables.$device-sm) {
        flex: 0 0 1.5rem;
      }
    }

    > :last-child {
      margin-left: 0.25rem;
      transition: variables.$transition-s;

      @if not $bg {
        margin-left: 0.125rem;
        opacity: 0.25;
      }

      @media (max-width: variables.$device-sm) {
        flex: 0 0 1.25rem;
        margin-left: 0;
      }
    }
  }
}

@mixin page-header($has-tabs: false, $hide-gradient: false) {
  @if not $hide-gradient {
    @include variables.rainbow-gradient-background($position: 'bottom', $flip: true);
  }
  height: 9.5rem;

  @media (max-width: variables.$device-md) {
    height: 6.5rem;
  }

  @if $has-tabs {
    margin-bottom: -1.5rem;
    padding-bottom: 3.5rem;

    @media (max-width: variables.$device-md) {
      margin-bottom: -1rem;
      padding-bottom: 3.5rem;
    }
  } @else {
    margin-bottom: 3rem;
    padding-bottom: 3.5rem;

    @media (max-width: variables.$device-md) {
      padding-top: 3.5rem;
      padding-bottom: 2rem;
    }
  }

  &__content {
    @include layout.max-width;
    @include layout.row;
    justify-content: space-between;
    align-items: flex-end;
    height: 100%;

    @media (max-width: variables.$device-md) {
      flex-direction: column;
      gap: 1rem;
      justify-content: flex-end;
      align-items: flex-start;
      height: 4rem;
      padding-top: 1rem;
    }

    h1 {
      @include variables.title(2);
      font-family: 'GT Haptik', Graphik, sans-serif;
      color: variables.$color-dark-light;

      @media (max-width: variables.$device-md) {
        padding-left: 0;
      }
    }

    > div > p {
      @include variables.paragraph(true);
      max-width: 40rem;
      margin-top: 0.325rem;
      color: variables.$color-dark-light;
    }

    & > :first-child {
      margin-top: -1rem;
      border-right: none;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}

@mixin ticker-label {
  font-size: 0.85em;
  color: variables.$color-purple-very-dark;
}
