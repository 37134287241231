@use 'libs/styles/variables';
@use 'sass:color';

@mixin pill-color($color) {
  color: $color;

  a &.outline:hover {
    border-color: $color;
    background-color: rgba($color, 0.1);
  }

  &:not(.outline) {
    background-color: rgba($color, 0.15);
  }

  &.fill {
    color: white;
    background: $color;
  }
}

.pill {
  @include variables.header(3);
  display: inline-flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  padding: 0.5rem 1.5rem;
  background-color: variables.$color-off-light;
  transition: variables.$transition-s;

  & > * {
    color: variables.$color-disabled;
  }

  &.selectable {
    cursor: pointer;

    &:hover {
      background-color: color.adjust(variables.$color-org, $lightness: 15%);
    }
  }

  &.color-orange {
    @include pill-color(variables.$color-fund);
  }

  &.color-violet {
    @include pill-color(variables.$color-org-2);
  }

  &.color-purple {
    @include pill-color(variables.$color-purple);
  }

  &.color-blue {
    @include pill-color(variables.$color-org);
  }

  &.color-green {
    @include pill-color(variables.$color-dark-green);
  }

  &.color-red {
    @include pill-color(variables.$color-error);
  }

  &.outline {
    border: 1px solid variables.$color-lighter;
    color: currentcolor;
    background: none;
    transition: variables.$transition-s;

    &.color-orange {
      border-color: rgba(variables.$color-fund, 0.5);
      color: variables.$color-fund;
      box-shadow: variables.$box-shadow-light-orange;
    }

    &.color-purple {
      border-color: rgba(variables.$color-purple, 0.5);
      color: variables.$color-purple;
      box-shadow: variables.$box-shadow-light-purple;
    }

    &.color-violet {
      border-color: rgba(variables.$color-org-2, 0.5);
      color: variables.$color-org-2;
      box-shadow: variables.$box-shadow-light-purple;
    }

    &.color-blue {
      border-color: rgba(variables.$color-org, 0.5);
      color: variables.$color-org;
      box-shadow: variables.$box-shadow-light-blue;
    }
  }

  &.pad {
    padding: 1rem;
  }
}

.size-tiny {
  @include variables.paragraph;
  gap: 6px;
  border-radius: 16px;
  padding: 3px 12px;
  font-weight: 500;

  svg {
    width: 16px;
    height: 16px;
    margin-left: -4px;
    color: currentcolor;
  }

  &.rectangle {
    border-radius: 10px;
  }
}

.size-large {
  border-radius: 16px;
  padding: 12px 28px;

  @media (max-width: variables.$device-md) {
    padding: 12px 24px;
  }
}
