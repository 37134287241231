@use 'libs/styles/variables';

.card {
  display: flex;
  border: 1px solid variables.$color-lighter;
  border-radius: variables.$space-l;
  padding: 2rem;
  background-color: variables.$color-white;
  box-shadow: variables.$box-shadow-medium;

  @media (max-width: variables.$device-md) {
    padding: 1.25rem;
  }

  &:hover {
    text-decoration: none;
  }

  [class*='avatar']:not([class*='avatar-container']) {
    transition: variables.$transition-m;
  }

  &--no-padding {
    padding: 0;
  }

  &--no-shadow {
    box-shadow: none;
  }

  &--link {
    transition: variables.$transition-s;

    &:hover {
      border-color: rgba(variables.$color-purple, 0.5);
    }
  }

  &--mobile-fw {
    @media (max-width: variables.$device-md) {
      border-right: none;
      border-left: none;
      border-radius: 0;
      box-shadow: none;
    }
  }
}

.rainbow-card {
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 25px;
  padding: 2px;
  overflow: hidden;
  box-shadow: 0 9.7112px 31.0758px rgba(234, 107, 14, 10%);

  &::before {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    background: linear-gradient(50.39deg, #53acde -22.24%, #6356db 32.33%, #d94002 91.96%, #ea6b0e 139.79%);
    content: '';
  }
}

.rainbow-content {
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: variables.$space-l;
  background: variables.$color-white;
}
