@use 'libs/styles/components';
@use 'libs/styles/variables';

.ticker-label {
  @include components.ticker-label;
}

.token-amount {
  svg {
    border-radius: 100rem;
    box-shadow: variables.$box-shadow-tiny;
  }

  &--large {
    @include variables.title(1);
    color: variables.$color-purple-dark;

    @media (max-width: variables.$device-md) {
      @include variables.title(3);
      color: variables.$color-purple-dark;

      svg {
        width: 2rem;
        height: 2rem;
      }
    }
  }

  &--medium {
    @include variables.title(5);
    color: variables.$color-purple-dark;
  }

  &--small {
    @include variables.title(6);
    color: variables.$color-purple-dark;

    b {
      color: variables.$color-purple-very-dark;
    }
  }

  em {
    font-style: normal;
    color: variables.$color-purple;
  }
}
